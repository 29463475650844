$(function () {
    $(window).scroll(function(){
        // add navbar opacity on scroll
        if ($(this).scrollTop() > 100) {
            $(".navbar.navbar-fixed-top").addClass("scroll");
        } else {
            $(".navbar.navbar-fixed-top").removeClass("scroll");
        }

        // global scroll to top button
        if ($(this).scrollTop() > 300) {
            $('.scrolltop').fadeIn();
        } else {
            $('.scrolltop').fadeOut();
        }
    });

    // scroll back to top btn
    $('.scrolltop').click(function(){
        $("html, body").animate({ scrollTop: 0 }, 700);
        return false;
    });

    // scroll navigation functionality
    $('.scroller').click(function(){
    	var section = $($(this).data("section"));
    	var top = section.offset().top;
        $("html, body").animate({ scrollTop: top }, 700);
        return false;
    });

    // FAQs
    var $faqs = $("#faq .faq");
    $faqs.click(function () {
        var $answer = $(this).find(".answer");
        $answer.slideToggle('fast');
    });

    if (!$.support.leadingWhitespace) {
        //IE7 and 8 stuff
        $("body").addClass("old-ie");
    }

    // MAC Button Animations
    function showMacStoreButtons() {
      $('.macstorebuttons').removeClass("animated bounceOutRight");
      $('.macstorebuttons').addClass("animated bounceInRight");
      $('.macstorebuttons').show();
    }

    function hideMacStoreButtons() {
      $('.macstorebuttons').removeClass("animated bounceInRight");
      $('.macstorebuttons').addClass("animated bounceOutRight");
    }

    $('.macbutton').hover(function(){
      showMacStoreButtons();
    });

    $('.macbutton').click(function(){
      showMacStoreButtons();
    });

    $('.iosbutton').hover(function(){
      hideMacStoreButtons();
    });

    // GOOGLE EVENTS
    $('.macbutton').on('click', function() {
      ga('send', 'event', 'button', 'click', 'mac-button');
    });

    $('.iosbutton').on('click', function() {
      ga('send', 'event', 'button', 'click', 'ios-button');
    });

    $('.masbutton').on('click', function() {
      ga('send', 'event', 'button', 'click', 'mas-button');
    });

    $('.trialbutton').on('click', function() {
      ga('send', 'event', 'button', 'click', 'trial-button');
    });

    $('.iosbadge').on('click', function() {
      ga('send', 'event', 'button', 'click', 'ios-badge');
    });

    $('.masbadge').on('click', function() {
      ga('send', 'event', 'button', 'click', 'mas-badge');
    });

    $('.trialbadge').on('click', function() {
      ga('send', 'event', 'button', 'click', 'trial-badge');
    });

});